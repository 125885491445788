import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { uuidToShortString } from '../../utils/common';

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'senninhaTrackingDeliveries';
const GET_TRACKING_DELIVERIES = 'senninhaTrackingDeliveries/getTrackingDeliveries'
const GET_TRACKING_DELIVERIES_PRE_BAIXADA = 'senninhaTrackingDeliveries/getTrackingDeliveriesPreBaixada'
const POST_TRACKING_DELIVERIES_PRE_BAIXADA = 'senninhaTrackingDeliveries/postTrackingDeliveriesPreBaixada'
const DELETE_TRACKING_DELIVERy = 'senninhaTrackingDeliveries/deleteTrackingDelivery'

const initialState = {
  deliveries: null,
  deliveriesPreBaixada: null,
  statusGetDeliveries: 'idle',
  statusGetDeliveriesPreBaixada: 'idle',
  statusPostDeliveriesPreBaixada: 'idle',
  statusDeleteDelivery: 'idle',
  errorGetDeliveries: null,
  errorGetDeliveriesPreBaixada: null,
  errorPostDeliveriesPreBaixada: null,
  errorDeleteDelivery: null,
};

export const getTrackingDeliveries = createAsyncThunk(GET_TRACKING_DELIVERIES, async (provider, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/any/buscar_delivery/${provider}`);
    const resFormatted = await Promise.all(res.data.map(async (item) => ({
      ...item,
      lote: await uuidToShortString(item.reference_key),
    })))

    return resFormatted;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.erro || 'error');
  }
});

export const getTrackingDeliveriesPreBaixada = createAsyncThunk(GET_TRACKING_DELIVERIES_PRE_BAIXADA, async (provider, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/any/deliveries_pre_baixada/${provider}`);

    const resFormatted = await Promise.all(res.data.map(async (item) => ({
      ...item,
      lote: await uuidToShortString(item.reference_key),
    })))

    return resFormatted;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.erro || 'error');
  }
});

export const postTrackingDeliveryPreBaixada = createAsyncThunk(POST_TRACKING_DELIVERIES_PRE_BAIXADA, async (data, { rejectWithValue, dispatch }) => {
  try {
    await LogisticService.postWithAuth(`${BASE_URL}/any/baixar_deliveries`, data.payload);

    dispatch(removeFromDeliveriesPreBaixadas(data.refIds))
    return
  } catch (err) {
    return rejectWithValue(err?.response?.data?.erro || 'error');
  }
});

export const deleteTrackingDelivery = createAsyncThunk(DELETE_TRACKING_DELIVERy, async (data, { rejectWithValue }) => {
  try {
      await LogisticService.deleteWithAuth(`${BASE_URL}/any/cancelar_delivery/${data.reference_key}`);
    return 
  } catch (err) {
    return rejectWithValue(err?.response?.data?.erro || 'error');
  }
});

export const trackingDeliveriesSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    removeFromDeliveries: (state, action) => {
      state.deliveries = state.deliveries.filter((item) => 
        item.reference_key !== action.payload
      )
    },
    removeFromDeliveriesPreBaixadas: (state, action) => {
      state.deliveriesPreBaixada = state.deliveriesPreBaixada.filter((item) => 
        !action.payload.includes(item.reference_key)
      )
    },
    resetDeleteDeliveryStates: (state) => {
      state.statusDeleteDelivery = 'idle'
      state.errorDeleteDelivery = null
    },
    resetDeliveryPreBaixadaStates: (state) => {
      state.statusPostDeliveriesPreBaixada = 'idle'
      state.errorPostDeliveriesPreBaixada = null
    },
    resetTrackingDeliveriesSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrackingDeliveries.pending, (state) => {
        state.deliveries = null;
        state.statusGetDeliveries = 'pending';
        state.errorGetDeliveries = null;
      })
      .addCase(getTrackingDeliveries.fulfilled, (state, action) => {
        state.deliveries = action.payload;
        state.statusGetDeliveries = 'fulfilled';
      })
      .addCase(getTrackingDeliveries.rejected, (state, action) => {
        state.deliveries = null;
        state.errorGetDeliveries = action.payload;
        state.statusGetDeliveries = 'rejected';
      })

      .addCase(deleteTrackingDelivery.pending, (state) => {
        state.statusDeleteDelivery = 'pending';
        state.errorDeleteDelivery = null;
      })
      .addCase(deleteTrackingDelivery.fulfilled, (state) => {
        state.statusDeleteDelivery = 'fulfilled';
      })
      .addCase(deleteTrackingDelivery.rejected, (state, action) => {
        state.errorDeleteDelivery = action.payload;
        state.statusDeleteDelivery = 'rejected';
      })

      .addCase(getTrackingDeliveriesPreBaixada.pending, (state) => {
        state.deliveriesPreBaixada = null;
        state.statusGetDeliveriesPreBaixada = 'pending';
        state.errorGetDeliveriesPreBaixada = null;
      })
      .addCase(getTrackingDeliveriesPreBaixada.fulfilled, (state, action) => {
        state.deliveriesPreBaixada = action.payload;
        state.statusGetDeliveriesPreBaixada = 'fulfilled';
      })
      .addCase(getTrackingDeliveriesPreBaixada.rejected, (state, action) => {
        state.deliveriesPreBaixada = null;
        state.errorGetDeliveriesPreBaixada = action.payload;
        state.statusGetDeliveriesPreBaixada = 'rejected';
      })
      
      .addCase(postTrackingDeliveryPreBaixada.pending, (state) => {
        state.statusPostDeliveriesPreBaixada = 'pending';
        state.errorPostDeliveriesPreBaixada = null;
      })
      .addCase(postTrackingDeliveryPreBaixada.fulfilled, (state, action) => {
        state.statusPostDeliveriesPreBaixada = 'fulfilled';
      })
      .addCase(postTrackingDeliveryPreBaixada.rejected, (state, action) => {
        state.errorPostDeliveriesPreBaixada = action.payload;
        state.statusPostDeliveriesPreBaixada = 'rejected';
      });;
  },
});

export const {
  removeFromDeliveries,
  removeFromDeliveriesPreBaixadas,
  resetDeliveryPreBaixadaStates,
  resetDeleteDeliveryStates,
  resetTrackingDeliveriesSlice
} = trackingDeliveriesSlice.actions

export const trackingDeliveriesSelector = (state) => state.trackingDeliveriesSlice;

export default trackingDeliveriesSlice.reducer;
