import React, { useEffect, useState } from "react";
import { Form, Cascader, Radio, Button } from "antd";
import { PageLayout } from "../../../../Layout/PageLayout";
import "antd/dist/antd.css";
import {
  CompassOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";

import Page from "@components/Page";
import Box from "@components/Box";
import Container from "@components/Container";
import Row from "@components/Row";
import Dragger from "@components/CustomDragger";
import InfoTable from "@components/InfoTable";
import WeekPicker from "@components/WeekPicker";

import DefaultInput from "@components/DefaultInput";
import SubmitButton from "@components/SubmitButton";
import SelectBox from "@components/SelectBox";

import LogisticService from "@LogisticService";

import { Download, Exemplo, Span, TextError, TextSuccess } from "../styles";
import { CANAIS, OPERADORES, ORIGENS, STATUS, TIPOS, FILAS } from "./constants";
import Colors from "@colors";
// import { useAuthContext } from '../../../../stonelog-auth-components';
import { useAuthContext } from "@stonelog/stonelog-auth-components";

export default function ClassificacaoCasos(props) {
  const auth = useAuthContext();
  const userName = auth.getUser();
  const [successfully, setSuccessfully] = useState("wait");
  const [success, setSuccess] = useState("wait");
  const [successfullyMultiple, setSuccessfullyMultiple] = useState("wait");

  const [dataDragger, setDataDragger] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);

  const [polos, setPolos] = useState([]);
  const [disabledPolos, setDisabledPolos] = useState(true);
  const [selectedPoloName, setSelectedPoloName] = useState(null);

  const [distritoRegiao, setDistritoRegiao] = useState("Distrito / Região");

  const [tca, setTca] = useState([]);
  const [disabledTca, setDisabledTca] = useState(true);

  const [emailsMembros, setEmailsMembros] = useState([]);
  const [selectedEmailMembro, setSelectedEmailMembro] = useState(
    userName.email
  );
  const [disabledEmailMembro, setDisabledEmailMembro] = useState(true);
  const [selectCategoriaExtra, setSelectCategoriaExtra] = useState(1);
  const [response, setResponse] = useState({});

  const [selectedTipo, setSelectedTipo] = useState(null);
  const [savedFieldValue, setSavedFieldValue] = useState(null);

  const [horariosDeFuncionamento, setHorariosDeFuncionamento] = useState([]);
  const formRef = React.createRef();
  const formInputRef = React.createRef();
  const multipleFormRef = React.createRef();

  const [form] = Form.useForm();
  const [formInput] = Form.useForm();
  const [multipleForm] = Form.useForm();

  const opcoesExtra = [
    { id: 1, name: "Problemas de internet" },
    { id: 2, name: "1:1" },
    { id: 3, name: "Problema pessoal" },
    { id: 4, name: "Projeto" },
    { id: 5, name: "Reunião" },
  ];
  useEffect(() => {
    let horario = [];
    Object.keys(horariosDeFuncionamento).forEach((key) => {
      if (horariosDeFuncionamento[key].state === true) {
        horario.push(
          `${key}: de ${horariosDeFuncionamento[key].open} às ${horariosDeFuncionamento[key].closed}`
        );
      }
    });
    setResponse({ ...response, horario_funcionamento: horario.join(", ") });
  }, [horariosDeFuncionamento]);

  useEffect(() => {
    LogisticService.getCasos().then((response) => {
      if (response) {
        const data = response.data.data;
        const options = Object.keys(data).map((tema) => {
          return {
            value: tema,
            label: tema,
            children: Object.keys(data[tema]).map((cat) => {
              return {
                value: cat,
                label: cat,
                children:
                  data[tema][cat].length <= 1 &&
                  (data[tema][cat].includes("") ||
                    data[tema][cat].includes(null) ||
                    data[tema][cat].includes("-"))
                    ? undefined
                    : data[tema][cat].map((assunto) => {
                        return {
                          value: assunto,
                          label: assunto,
                        };
                      }),
              };
            }),
          };
        });
        setTca(options);
        setDisabledTca(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getEmailsMembros().then((response) => {
      if (response) {
        const emails = [{ id: 1, name: userName.email }];
        response.data.data.map((item) => {
          emails.push({ id: emails.length + 1, name: item.member_mail });
          return null;
        });

        setEmailsMembros(emails);
        setDisabledEmailMembro(false);
      }
    });
  }, []);

  useEffect(() => {
    LogisticService.getPolos().then((response) => {
      if (response) {
        setPolos(response.data);
        setDisabledPolos(false);
      }
    });
  }, []);

  const onChangePolo = (key, value) => {
    if (value) setSelectedPoloName(value.children);
    else setSelectedPoloName(null);
    Object.keys(polos).forEach(function (polo) {
      if (polos[polo].id === key) {
        setDistritoRegiao(`${polos[polo].district} / ${polos[polo].region}`);
      }
    });
  };

  const adicionarResultado = (status, formObj) => {
    let result;
    const date = new Date();

    if (status === 200) {
      result = () => {
        return (
          <TextSuccess>
            <CheckCircleOutlined /> Sim
          </TextSuccess>
        );
      };
    } else {
      result = () => {
        return (
          <TextError>
            <StopOutlined /> Nop
          </TextError>
        );
      };
    }

    formObj = {
      inserido: result(),
      horario: date.getHours() + ":" + date.getMinutes(),
      ...formObj,
    };
    setDadosTabela((oldState) => [formObj, ...oldState]);
  };

  const postForm = async (data) => {
    if (data) {
      return LogisticService.postCasos(data)
        .then((response) => {
          if (response) {
            return response.status;
          }
        })
        .catch((response) => {
          return response;
        });
    }
  };

  const onFinish = async (data) => {
    const formObj = {
      order_number: data.order_number || null,
      queue: data.queue || "",
      numero: data.numero,
      tipo: data.tipo || null,
      descricao: data.descricao || null,
      tema: data.tca ? data.tca[0] : null,
      categoria: data.tca ? (data.tca.length > 1 ? data.tca[1] : null) : null,
      assunto: data.tca ? (data.tca.length > 2 ? data.tca[2] : null) : null,
      operador: data.operador ? OPERADORES[data.operador - 1].name : null,
      canal: data.canal ? CANAIS[data.canal - 1].name : null,
      origem: data.origem ? ORIGENS[data.origem - 1].name : null,
      status: data.status ? STATUS[data.status - 1].name : null,
      email: selectedEmailMembro,
      polo: selectedPoloName,
    };
    const status = await postForm(formObj);
    adicionarResultado(status, formObj);

    if (status === 200) {
      setSuccessfully("ok");
      const queue = data.queue;
      form.resetFields();
      form.setFieldsValue({ queue: queue });
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    } else {
      setSuccessfully("erro");
      setTimeout(() => {
        setSuccessfully("wait");
      }, 3000);
    }
  };
  const onFinishAusencia = async (data) => {
    const formInput = {
      categoria: opcoesExtra[selectCategoriaExtra - 1].name,
      tema: response.horario_funcionamento,
      numero: 0,
      assunto: data.topico || null,
      email: selectedEmailMembro,
    };
    const status = await postForm(formInput);
    adicionarResultado(status, formInput);
    if (status === 200) {
      setSuccess("ok");
      form.resetFields();
      setTimeout(() => {
        setSuccess("wait");
      }, 3000);
    } else {
      setSuccess("erro");
      setTimeout(() => {
        setSuccess("wait");
      }, 3000);
    }
  };

  const onFinishMultipleCases = async (data) => {
    dataDragger.forEach(async (formObj) => {
      const status = await postForm(formObj);
      adicionarResultado(status, formObj);

      if (status === 200) {
        setSuccessfullyMultiple("ok");
        form.resetFields();
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      } else {
        setSuccessfullyMultiple("erro");
        setTimeout(() => {
          setSuccessfullyMultiple("wait");
        }, 3000);
      }
    });
    multipleForm.resetFields();
  };

  const handleOnUpload = (dataFileDragger) => {
    setDataDragger(dataFileDragger);
  };

  const filterTCA = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const onClickTipo = (e) => {
    if (selectedTipo === e.target.value) {
      form.setFieldsValue({ tipo: null });
      setSelectedTipo(null);
    } else {
      setSelectedTipo(e.target.value);
    }
  };

  const colunasTabela = [
    { label: "Inserido?", key: "inserido" },
    { label: "Horário", key: "horario" },
    { label: "Número", key: "numero" },
    // { label: 'OSs', key: 'pos_qty' },
    // { label: 'Tema', key: 'tema' },
    { label: "Categoria", key: "categoria" },
    { label: "Assunto", key: "assunto" },
    // { label: 'Origem', key: 'origem' },
    // { label: 'Operador', key: 'operador' },
    // { label: 'Canal', key: 'canal' },
    { label: "Status", key: "status" },
    { label: "Email", key: "email" },
    // { label: 'SC', key: 'stonecode' },
    // { label: 'Polo', key: 'polo' },
    // { label: 'Tipo', key: 'tipo' },
  ];

  const tableData = {
    data: dadosTabela,
    columns: colunasTabela,
  };

  const radios = TIPOS.map((tipo) => {
    return (
      <Radio.Button value={tipo.value} onClick={onClickTipo}>
        {tipo.label}
      </Radio.Button>
    );
  });

  const rows = [
    <Row horizontalAlign={"left"}>
      <Form.Item
        name="numero"
        label="Número do Caso"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Informe o número do caso!" }]}
      >
        <DefaultInput
          type={"Number"}
          min={0}
          style={{ width: "180px" }}
          autoFocus={true}
        />
      </Form.Item>
      <Form.Item
        name="order_number"
        label="Número da OS"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Informe o número da OS!" }]}
      >
        <DefaultInput style={{ width: "180px" }} />
      </Form.Item>
      <Form.Item
        name="queue"
        label="Fila"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Informe a fila!" }]}
      >
        <SelectBox
          placeholder="Selecione"
          allowClear
          options={FILAS}
          rules={[{ required: true, message: "Informe a fila!" }]}
        />
      </Form.Item>
    </Row>,

    <Row>
      <Form.Item
        name="tca"
        label="Tema, Categoria e Assunto"
        labelCol={{ span: 24, style: { margin: "0 60px 0 0" } }}
        rules={[
          { required: true, message: "Selecione Tema, Categoria e Assunto!" },
        ]}
      >
        <Cascader
          options={tca}
          placeholder="Selecione"
          disabled={disabledTca}
          showSearch={{ filter: filterTCA, matchInputWidth: true }}
          size={"large"}
        />
      </Form.Item>
    </Row>,

    <Row horizontalAlign={"left"}>
      <Form.Item
        name="origem"
        label="Origem do Caso"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: "Informe a origem do caso!" }]}
      >
        <SelectBox placeholder="Selecione" allowClear options={ORIGENS} />
      </Form.Item>
    </Row>,

    <Row horizontalAlign={"left"}>
      <Form.Item
        name="operador"
        label={"Operador"}
        labelCol={{ span: 24, style: { margin: "0 40px 0 0" } }}
        rules={[{ required: true, message: "Informe o operador do caso!" }]}
      >
        <SelectBox placeholder="Selecione" allowClear options={OPERADORES} />
      </Form.Item>
      <Form.Item name="email" label="Email" labelCol={{ span: 24 }}>
        <SelectBox
          placeholder="Selecione"
          options={emailsMembros}
          defaultValue={userName.email}
          onChange={(key) => {
            setSelectedEmailMembro(emailsMembros[key - 1].name);
          }}
          disabled={disabledEmailMembro}
          width={"17.18rem"}
        />
      </Form.Item>
    </Row>,

    <Row>
      <Form.Item
        name="polo"
        label="Polo"
        rules={[{ required: true, message: "Informe o polo!" }]}
      >
        <SelectBox
          placeholder="Polo"
          width={400}
          options={polos}
          allowClear
          disabled={disabledPolos}
          onChange={onChangePolo}
        />
      </Form.Item>
      <Span color={Colors.textGreen}>
        <CompassOutlined /> {distritoRegiao}
      </Span>
    </Row>,

    <Row margin={"0 77px 0 0"}>
      <Form.Item name="descricao" label="Descrição:">
        <DefaultInput
          placeholder={"Informe Detalhes Sobre o Caso"}
          type={"TextArea"}
          style={{ width: "450px" }}
        />
      </Form.Item>
      <SubmitButton
        verifyer={successfully}
        textSuccess="Caso Registrado Com Sucesso! （っ＾▿＾）"
        textError="Falha Ao Registrar o Caso! ( ˘︹˘ )"
      >
        REGISTRAR CASO
      </SubmitButton>
    </Row>,
  ];

  return (
    <PageLayout>
      <div style={{ width: "77vw" }}>
        <Box title="Classificação dos Casos" />
        <Row horizontalAlign={"center"}>
          <Container
            grid={1}
            title={`Olá, ${userName.name.replace(" | Stone", "")}!`}
            padding={"0 16px 16px 16px"}
            rounded={true}
            margin={"1rem"}
          >
            <Form onFinish={onFinish} form={form} ref={formRef}>
              {rows}
            </Form>
          </Container>
        </Row>
        <Container rounded={true}>
          <Form onFinish={onFinishAusencia} form={formInput} ref={formInputRef}>
            <Row margin={"10px 530px -2px 20px"}>
              <Form.Item
                name="categoria"
                label="Mapeamento de Ausência:"
                labelCol={{ span: 24, style: { margin: "0 60px 0 0" } }}
                rules={[
                  {
                    required: true,
                    message: "Selecione o Motivo de Ausência!",
                  },
                ]}
              >
                <SelectBox
                  placeholder="Tema"
                  options={opcoesExtra}
                  defaultValue={""}
                  onChange={(key) => {
                    setSelectCategoriaExtra(key);
                  }}
                  disabled={false}
                />
              </Form.Item>
              <WeekPicker
                name="tema"
                setHorariosDeFuncionamento={setHorariosDeFuncionamento}
                changeAll={false}
                defaultValues={{
                  segunda: { state: false, open: null, closed: null },
                  terça: { state: false, open: null, closed: null },
                  quarta: { state: false, open: null, closed: null },
                  quinta: { state: false, open: null, closed: null },
                  sexta: { state: false, open: null, closed: null },
                  sábado: { state: false, open: null, closed: null },
                  domingo: { state: false, open: null, closed: null },
                }}
              />
              <div>
                <Form.Item name="topico" label="Descrição:">
                  <DefaultInput
                    placeholder={"Informe Detalhes"}
                    type={"TextArea"}
                    style={{ width: "450px" }}
                  />
                </Form.Item>

                <Form.Item name="email" label="Email" labelCol={{ span: 24 }}>
                  <SelectBox
                    placeholder="Selecione"
                    options={emailsMembros}
                    defaultValue={userName.email}
                    disabled={false}
                    onChange={(key) => {
                      setSelectedEmailMembro(emailsMembros[key - 1].name);
                    }}
                    width={"17.189rem"}
                  />
                </Form.Item>
              </div>
            </Row>
            <Row verticalAlign={"flex-end"}>
              <SubmitButton
                verifyer={success}
                textSuccess="Motivo Registrado Com Sucesso!"
                textError="Falha Ao Registrar o Motivo!"
              >
                REGISTRAR
              </SubmitButton>
            </Row>
          </Form>
        </Container>
        <Row horizontalAlign={"center"}>
          <Container grid={3 / 10} padding={"16px"} rounded={true}>
            <Form
              onFinish={onFinishMultipleCases}
              form={multipleForm}
              ref={multipleFormRef}
            >
              <Row>
                <Span>Importação de Múltiplos Casos</Span>
              </Row>
              <Row>
                <Form.Item
                  name="dragger"
                  rules={[
                    {
                      required: true,
                      message: "Nenhum Arquivo CSV Selecionado!",
                    },
                  ]}
                >
                  <Dragger
                    name="dragger"
                    title="Importar Múltiplos Casos Para a Base de Dados"
                    text="Selecione ou Arraste um Arquivo XLSX/CSV Até Esta Área"
                    handleOnUpload={handleOnUpload}
                    multipleFormRef={multipleFormRef}
                  />
                </Form.Item>
              </Row>
              <Row verticalAlign={"center"}>
                <SubmitButton
                  verifyer={successfullyMultiple}
                  textSuccess="Registrado Com Sucesso! (•◡•)"
                  textError="Falha Ao Registrar! ( ˘︹˘ )"
                >
                  REGISTRAR MÚLTIPLOS CASOS
                </SubmitButton>
              </Row>
              <Exemplo>
                Dúvidas? Clique{" "}
                <Download href={"/dist/Modelo Casos.csv"} target="_blank">
                  aqui
                </Download>{" "}
                para baixar um modelo de exemplo.
              </Exemplo>
            </Form>
          </Container>
          <Container grid={7 / 10} rounded={true}>
            <Row>
              <Span style={{ padding: "16px" }}>
                Visualização dos Casos Importados
              </Span>
            </Row>
            <Row>
              <InfoTable
                data={tableData}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              />
            </Row>
          </Container>
        </Row>
      </div>
    </PageLayout>
  );
}
